module.exports = [{
      plugin: require('../node_modules/@pauliescanlon/gatsby-mdx-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-copy-linked-files","gatsby-remark-smartypants",{"resolve":"gatsby-remark-images","options":{"maxWidth":640,"withWebp":true,"tracedSVG":true}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/capsulecat.github.io/capsulecat.github.io"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-72108944-5","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Capsule Cat","short_name":"Capsule Cat","start_url":"/","background_color":"#f7f0eb","theme_color":"#3D8DD3","display":"minimal-ui","icon":"./static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2b06399715fcf5b1402c3b24323acebe"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
